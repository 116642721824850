// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import FullCalendar from "@fullcalendar/react";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Box, Button, Radio, Tab, Tabs, Typography } from "@material-ui/core";
import Toast from "../../../components/src/Toast";
import { format } from "date-fns";
import Select, { ValueType } from "react-select";
import { DockDetail } from "./DockBookingController.web";

export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  navigation: any;
  propId: string;
}

export interface IEvent {
  title: string;
  start: string;
  end: string;
  id: string;
  temp_id: string;
}

interface IEventDetail {
  title: string;
  address: string;
  image: string;
  startDate: string;
  endDate: string;
  guests: number;
  bookedBy: string;
  price_details: {
    base_price: number;
    your_earnings: number;
    guest_total: number;
    total_pay: number;
  };
}

export interface ILakeListItem {
  id: number;
  listing_title: string;
  address: string | null;
  image: string;
  base_price: string;
  weekend_price: number;
  service_fee: number;
}

interface SProps {
  isLoading: boolean;
  lakeListModal: boolean;
  lakeModalError: string;
  lakeList: ILakeListItem[];
  tempSelectedDock: ILakeListItem,
  selectedDock: ILakeListItem;
  events: IEvent[];
  selectedEventDetails: IEventDetail;
  selectedEventDate: Date | null;
  selectedDate: string | null;
  isDateClicked: boolean;
  dateOption: "Available" | "Block";
  blockedDates: Date[];
  currentMonth: number;
  currentYear: number;
  errorMsg: string;
  availableErrorMsg: string;
  noDockErrorMsg: string;
  currentPage: number;
  totalPages: number;
}

interface SSProps {
  ssId: string;
}

export default class CalendarController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  calendarRef: FullCalendar | null = null;
  getLakeListApiCallId: string = "";
  getEventAndBlockdatesApiCallId: string = "";
  getEventDetailApiCallId: string = "";
  markDateApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      lakeListModal: false,
      lakeModalError: "",
      lakeList: [],
      tempSelectedDock: {
        id: -1,
        listing_title: "",
        address: "",
        image: "",
        base_price: "0",
        weekend_price: 0,
        service_fee: 0,
      } as ILakeListItem,
      selectedDock: {
        id: -1,
        listing_title: "",
        address: "",
        image: "",
        base_price: "0",
        weekend_price: 0,
        service_fee: 0,
      } as ILakeListItem,
      events: [],
      selectedEventDetails: {
        title: "",
        address: "",
        image: "",
        startDate: new Date().toString(),
        endDate: new Date().toString(),
        guests: 0,
        bookedBy: "",
        price_details: {
          base_price: 0,
          your_earnings: 0,
          guest_total: 0,
          total_pay: 0,
        },
      },
      selectedEventDate: null,
      selectedDate: null,
      isDateClicked: false,
      dateOption: configJSON.blockText,
      blockedDates: [],
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      errorMsg: "",
      availableErrorMsg: "",
      noDockErrorMsg: "",
      currentPage: 1,
      totalPages: 1,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchLakeListData(1);
    const selectedLake = await getStorageData("selectedLake");
    if(selectedLake) {
      this.setState({ lakeListModal: false });
    } else if (this.state.selectedDock.id < 0) {
      this.setState({ lakeListModal: true });
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<SProps>
  ) {
    if (
      this.state.selectedDate &&
      prevState.selectedDate !== this.state.selectedDate
    ) {
      this.setState({
        dateOption: this.state.blockedDates
          .map((blockedDate) => format(new Date(blockedDate), "yyyy-MM-dd"))
          .includes(this.state.selectedDate)
          ? "Available"
          : "Block",
      });
    }

    if (
      this.state.selectedDock.id > 0 &&
      this.state.currentMonth !== prevState.currentMonth ||
      this.state.currentYear !== prevState.currentYear
    ) {
      this.fetchEventsList();
    }
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getLakeListApiCallId]: this.handleListingDataAPIResponse,
      [this.getEventAndBlockdatesApiCallId]:
        this.handleFetchEventAndBlockedDates,
      [this.getEventDetailApiCallId]: this.handleAPIResponse,
      [this.markDateApiCallId]: this.handleDateAvailabilityAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchLakeListData = async (page: number) => {
    this.setState({ errorMsg: "", availableErrorMsg: "", noDockErrorMsg: "" });
    const token = await getStorageData("token");
    this.getLakeListApiCallId = sendAPIRequest(
      `bx_block_content_management/dock_listings?page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  fetchEventsList = async () => {
    this.setState({ isLoading: true, errorMsg: "", availableErrorMsg: "", noDockErrorMsg: "" });
    const token = await getStorageData("token");
    this.getEventAndBlockdatesApiCallId = sendAPIRequest(
      `${configJSON.urlGetBlockdateList}?year=${this.state.currentYear}&month=${this.state.currentMonth}&dock_id=${this.state.selectedDock.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  fetchEventListByDate = async (eventId: number) => {
    this.setState({ isLoading: true, errorMsg: "", availableErrorMsg: "", noDockErrorMsg: "" });
    const token = await getStorageData("token");
    this.getEventDetailApiCallId = sendAPIRequest(
      `${configJSON.urlGetEventDetailByDate}?dock_id=${this.state.selectedDock.id}&reservation_id=${eventId}`,
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleListingDataAPIResponse = async (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const selectedLake = await getStorageData("selectedLake");
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: DockDetail }[];
      meta?: { pagination: { current_page: number; total_pages: number } };
      errors?: string[];
    };
    if (response.data && response.meta) {
      this.setState({
        lakeList: response.data?.map((listItem) => {
          return {
            id: listItem.attributes.id,
            listing_title: listItem.attributes.listing_title,
            image: listItem.attributes.images?.[0] || assets.calendarDetailIcon,
            address: listItem.attributes.address || "",
            base_price: listItem.attributes.base_price,
            weekend_price: listItem.attributes.weekend_price,
            service_fee: listItem.attributes.service_fee,
          };
        }),
        totalPages: response.meta.pagination?.total_pages || 1,
        currentPage: response.meta.pagination?.current_page || 1,
      }, () => {
        if(selectedLake)
          this.setState({
            selectedDock: this.state.lakeList.find(lake => lake.id === Number(selectedLake)) as ILakeListItem,
            lakeListModal: false,
          }, () => this.fetchEventsList());
      });
    }
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: {
          id: number;
          slot_start_time: string;
          slot_end_time: string;
          guest_count: number;
          boater_name: string;
          dock_details: {
            id: number;
            address: string;
            image: string;
            lake_details: { id: number; name: string };
          };
          price_details: {
            base_price: number;
            your_earnings: number;
            guest_total: number;
            total_pay: number;
          }
        };
      };
      errors: string;
    };
    if (response.data) {
      const selectedEventDetails = {
          title: response.data.attributes.dock_details.lake_details.name,
          address: response.data.attributes.dock_details.address,
          image: response.data.attributes.dock_details.image,
          startDate: response.data.attributes.slot_start_time,
          endDate: response.data.attributes.slot_end_time,
          guests: response.data.attributes.guest_count,
          bookedBy: response.data.attributes.boater_name,
          price_details: response.data.attributes.price_details,
        };
      this.setState({
        selectedEventDetails,
      });
    } else if (response.errors) {
      this.setState({
        availableErrorMsg: response.errors,
      });
    }
  };

  getEvents = (reservedDateData: { [date: string]: number[] }) => {
    const events: IEvent[] = [];
    Object.entries(reservedDateData).forEach(([date, dockIds]) => {
      dockIds.forEach((dockId) => {
        events.push({
          temp_id: dockId.toString(),
          start: date,
        } as IEvent);
      });
    });
    return events;
  };

  handleFetchEventAndBlockedDates = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: {
        reserved_docks: { [date: string]: number[] };
        blocked_dates: Date[];
      };
    };
    if (response.data) {
      this.setState({
        events: this.getEvents(response.data.reserved_docks),
        blockedDates: response.data.blocked_dates,
        lakeListModal: false,
      });
    }
  };

  handleDateAvailabilityAPIResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: {
          id: number;
          slot_date: string;
          account_id: number;
          status: string;
        };
      };
      meta?: string;
    };
    if (response.meta === "Availability updated") {
      this.fetchEventsList();
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: true });
    const { errors } = responseJSON as { errors: string };
    if (errors) {
        this.setState({ errorMsg: errors });
      return true;
    }
    return false;
  };

  handleDateClick = (info: { date: Date }) => {
    this.setState({
      selectedDate: format(info.date, "yyyy-MM-dd"),
    });
    this.handleCloseModal();
    const eventId = Number(this.getEventOnDate(info.date)?.temp_id || this.getEventOnDate(info.date)?.id);
    if (eventId && eventId > 0) {
      this.fetchEventListByDate(eventId);
      this.setState({
        selectedEventDate: info.date,
      });
    } else {
      this.setState({
        selectedEventDate: null,
      });
    }
  };

  getEventOnDate = (date: Date) => {
    return this.state.events.find((event) => {
      return event.start === format(date, "yyyy-MM-dd");
    });
  };

  handleCustomPriceButtonClick = () => {
    if(this.state.lakeList.length < 1) {
      this.setState({ noDockErrorMsg: "Please select dock first" });
      return;
    }
    this.setState({ selectedDate: null, isDateClicked: true, noDockErrorMsg: "" });
  };

  handleCloseModal = (selectedDock?: DockDetail) => {
    this.setState({
      isDateClicked: false,
    });
    if(selectedDock) {
      this.setState({
        selectedDock: {
          id: selectedDock.id,
          listing_title: selectedDock.listing_title,
          address: selectedDock.address,
          image: selectedDock.images[0],
          base_price: selectedDock.base_price,
          weekend_price: selectedDock.weekend_price,
          service_fee: selectedDock.service_fee,
        }
      });
    }
  };

  handleCloseLakeModal = () => {
    if (this.state.selectedDock.id > 0 || this.state.lakeList.length < 1) {
      this.setState({
        lakeListModal: false,
      });
    } else {
      this.setState({lakeModalError: "Please select lake"});
    }
  };

  handleDateOptionChange = (dateOption: "Available" | "Block") => {
    this.setState({
      dateOption,
    });
  };

  markSelectedDate = async () => {
    this.setState({ errorMsg: "", availableErrorMsg: "", noDockErrorMsg: "" });
    const token = await getStorageData("token");
    this.markDateApiCallId = sendAPIRequest(configJSON.urlPostDateOption, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token,
      },
      body: {
        availability: {
          slot_date: this.state.selectedDate,
          status: this.state.dateOption.toLowerCase(),
          dock_id: this.state.selectedDock.id,
        },
      },
    });
  };

  toggleLakeListPopup = () => {
    this.setState({
      lakeListModal: !this.state.lakeListModal,
      tempSelectedDock: this.state.selectedDock,
      lakeModalError: "",
    });
  };

  handleChangeSelectedLake = (
    selectedDock: ValueType<ILakeListItem, false>
  ) => {
    if (selectedDock)
      this.setState({
        tempSelectedDock: selectedDock,
        lakeModalError: "",
      });
  };

  fetchCalendarDetailsByLake = () => {
    this.setState({ errorMsg: "", availableErrorMsg: "", noDockErrorMsg: "" });
    const { tempSelectedDock } = this.state;
    if (tempSelectedDock.id > 0) {
      this.setState({ selectedDock: tempSelectedDock }, () => {
        setStorageData("selectedLake", tempSelectedDock.id);
        this.fetchEventsList();
      });
    } else {
      this.setState({
        lakeModalError: "Please select lake",
      });
    }
  };

  renderAvailabilityBox = () => {
    const { selectedDate, dateOption } = this.state;
    return (
      <>
        {selectedDate && (
          <Box className="borderedBox">
            <Typography variant="h6" className="headerTitle">
              {new Date(selectedDate).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </Typography>
            <Typography variant="body2" style={{ marginBottom: "10px" }}>
              {configJSON.blockDateHelperText}
            </Typography>
            <Tabs value={this.state.dateOption}>
              <Tab
                data-test-id="availableTextTab"
                label={configJSON.availableText}
                value={configJSON.availableText}
                className={`${
                  dateOption === configJSON.availableText ? "selectedTab" : ""
                }`}
                onClick={() =>
                  this.handleDateOptionChange(configJSON.availableText)
                }
              />
              <Tab
                data-test-id="blockTextTab"
                label={configJSON.blockText}
                value={configJSON.blockText}
                className={`${
                  dateOption === configJSON.blockText ? "selectedTab" : ""
                }`}
                onClick={() =>
                  this.handleDateOptionChange(configJSON.blockText)
                }
              />
            </Tabs>
            {this.state.errorMsg && <Toast message={this.state.errorMsg} />}
            <Button
              data-test-id="saveBtn"
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.markSelectedDate}
            >
              {configJSON.saveBtnText}
            </Button>
          </Box>
        )}
      </>
    );
  };

  renderSelectLakeListModal = () => {
    const { lakeModalError, lakeList, tempSelectedDock } = this.state;
    return (
      <>
        <Typography variant="h4" data-test-id="openLakeListModalTitle" className="lakeListTitle">
          {configJSON.selectListingText}
        </Typography>
        <Select
          data-test-id="selectList"
          options={lakeList}
          isMulti={false}
          value={lakeList.find((lake) => lake.id === tempSelectedDock.id) || null}
          noOptionsMessage={() => configJSON.noListedDockText}
          placeholder={
            <React.Fragment>
              <img
                src={assets.calendarDetailIcon}
                alt="Not Found"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "10px",
                }}
                loading="lazy"
              />{" "}
              {configJSON.selectText}
            </React.Fragment>
          }
          styles={{
            control: (provided) => ({
              ...provided,
              flexWrap: "nowrap",
              minHeight: "72px !important",
              border: "none",
              borderRadius: "8px",
              backgroundColor: "#EEE",
              cursor: "pointer"
            }),
            dropdownIndicator: () => ({
              color: "#1E293B",
              paddingRight: "20px",
            }),
            placeholder: () => ({
              paddingLeft: "4px",
              fontSize: "16px",
              fontFamily: "Outfit",
              opacity: 1,
              color: "#1E293B",
              fontWeight: 700,
              display: "flex",
              gap: 10,
              alignItems: "center",
            }),
            singleValue: () => ({
              paddingLeft: "4px",
              fontFamily: "Outfit",
              color: "#1E293B",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            noOptionsMessage: () => ({
              fontFamily: "Outfit",
              textAlign: "center",
              padding: "10px",
            }),
            menu: () => ({
              zIndex: 1,
              maxHeight: "270px",
              marginBottom: "20px",
            }),
            input: () => ({
              position: "absolute",
              height: "16px",
            }),
            valueContainer: (provided) => ({
              ...provided,
              overflow: "initial !important",
              input: {
                position: "absolute",
                fontFamily: "Outfit",
                fontSize: "1rem",
              },
            }),
          }}
          components={{
            Option: (props: any) => {
              const handleChange = () => {
                const newValue = props.selectOption(props.data);
                if (newValue) props.setValue(newValue, "select-option");
              };

              return (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor:
                      props.data.id === tempSelectedDock.id
                        ? "rgb(237, 245, 254)"
                        : "transparent",
                    padding: "10px",
                  }}
                  onClick={handleChange}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <img
                      src={props.data.image}
                      alt={props.data.listing_title}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "10px",
                      }}
                      loading="lazy"
                    />
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontFamily: "Outfit",
                        fontWeight: 400,
                        color: "#1E293B",
                      }}
                    >
                      <b>{props.data.listing_title}</b>, {props.data.address}
                    </Typography>
                  </Box>
                  <Radio
                    color="primary"
                    checked={props.data.id === tempSelectedDock.id}
                  />
                </Box>
              );
            },
            SingleValue: (props: any) => {
              return (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    padding: "10px 4px 10px 0",
                  }}
                >
                  <img
                    src={props.data.image}
                    alt={props.data.listing_title}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    style={{
                      fontFamily: "Outfit",
                      fontWeight: 400,
                      color: "#1E293B",
                    }}
                  >
                    <b>{props.data.listing_title}</b>, {props.data.address}
                  </Typography>
                </Box>
              );
            },
          }}
          isSearchable={false}
          onChange={(value) => this.handleChangeSelectedLake(value)}
          onMenuScrollToBottom={() => {
            const { currentPage, totalPages } = this.state;
            if (currentPage < totalPages) {
              this.fetchLakeListData(currentPage + 1);
            }
          }}
          onMenuOpen={() => this.setState({ lakeModalError: "" })}
        />
        {lakeModalError.length > 0 && (
          <Typography variant="subtitle2" color="error">
            * {lakeModalError}
          </Typography>
        )}
        <Button
          data-test-id="confirmBtn"
          variant="contained"
          color="primary"
          fullWidth
          className="confirmBtn"
          onClick={this.fetchCalendarDetailsByLake}
        >
          {configJSON.confirmBtnText}
        </Button>
      </>
    );
  };
}

// Customizable Area End
