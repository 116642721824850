// Customizable Area Start
export const imgListDock = require("../assets/Image_(1).png");
export const imgAcceptReservations = require("../assets/Image_(2).png");
export const imgGetPaid = require("../assets/Image_(3).png");
export const noReservationIcon = require("../assets/no_reservation.jpeg");
export const calendarDetailIcon = require("../assets/detail.jpg");
export const userProfileIcon = require("../assets/user_profile.png");
export const userLogo = require("../assets/user_logo.png");
export const reviewLogo = require("../assets/review.png");
export const starLogo = require("../assets/star.png");
export const chatIcon = require("../assets/chatIcon.png");
export const timeIcon = require("../assets/time.png");
// Customizable Area End
