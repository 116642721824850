import React from "react";
// Customizable Area Start
import { 
    Container, 
    Box, 
    Typography,
    Grid, 
    List, 
    ListItem, 
    ListItemText,
    ImageList,
    ImageListItem,
    ThemeProvider,
    Divider,
    ListItemAvatar,
    Button,
    styled,
    Avatar,
} from "@material-ui/core";
import Header from '../../../components/src/Header';
import { titleImg } from "./assets";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Wrapper, theme } from "../../../blocks/reservations/src/DockBooking.web"
import Toast from "../../../components/src/Toast";

const assets = require("./assets");

const listingType = (type: string)=> {
  return type === "whole_dock" ? 'Whole dock' : 'Slip only';
};
// Customizable Area End

import ListingPreviewController, {
  Props,
  configJSON,
} from "./ListingPreviewController.web";

export default class ListingPreview extends ListingPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { listedDockDetailsData, userDetails } = this.state;
      return (
        <ThemeProvider theme={theme}>
          <Container maxWidth="lg">
            <Header role="host" navigation={this.props.navigation} />
            <MainWrapper>
              <Button
                startIcon={<ArrowBackIosRoundedIcon />}
                onClick={this.handleBackBtnClick}
                data-test-id="backBtn"
                className="backBtn"
              >
                {configJSON.backBtnText}
              </Button>
              {this.state.errorMsg && (
                <Toast data-test-id="errorMsg" message={this.state.errorMsg} />
              )}
              <Box className="successBox">
                <img
                  src={titleImg}
                  alt={listedDockDetailsData.listing_title}
                  style={{width: "338px"}}
                  loading="lazy"
                />
                <Box>
                  <Typography variant="h5" className="fontWeight700" data-test-id="headerTitle">
                    {configJSON.titleTextListingDetails}
                  </Typography>
                  <Typography variant="h5">
                    {configJSON.greetingTextListingDetails}
                  </Typography>
                </Box>
              </Box>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                  <Box className="imageContainer">
                    <img
                      src={listedDockDetailsData.images?.[0] || assets.cardBackgroundImage}
                      alt="Lake Austin, Texas"
                      className="bigImage"
                      loading="lazy"
                    />
                    <ImageList
                      cols={4}
                      rowHeight={150}
                      component={"ul"}
                      className="imageListContainer"
                    >
                      {listedDockDetailsData.images?.slice(1)?.map((item) => (
                        <ImageListItem key={item} className="imageListItem">
                          <img
                            src={item}
                            alt={listedDockDetailsData.listing_title}
                            height={140}
                            className="smallImage"
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                  <Box className="dockDetailsContainer">
                    <Typography variant="h5" className="fontWeight700">
                      {listedDockDetailsData.listing_title?.trim() || "No Title"}
                    </Typography>
                    <Typography variant="body1" className="paddingTop5">
                      {listedDockDetailsData.about?.trim() || "No description"}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="ratingText fontWeight700"
                    >
                      <StarBorderRoundedIcon className="ratingIcon" />
                      <Typography variant="body1" className="fontWeight700">
                        {configJSON.noRatingsText}
                      </Typography>
                      <FiberManualRecordIcon
                        style={{
                          fontSize: "4px",
                          color: "#6B7280",
                          padding: "0 5px",
                        }}
                      />
                      <Typography variant="body1" className="fontWeight700">
                        {configJSON.noReviewsText}
                      </Typography>
                    </Typography>
                    <ListItem>
                      <ListItemAvatar>
                      <Avatar src={userDetails.profile_pic || assets.userLogo} />
                      </ListItemAvatar>
                      <ListItemText
                        secondary={configJSON.hostedByText}
                        primary={userDetails.first_name + " " + userDetails.last_name}
                      />
                    </ListItem>
                  </Box>
                  <Box className="dockPropertyBox">
                    <Box className="flexCol">
                      <Typography variant="body1" className="fontWeight700">
                        {listingType(listedDockDetailsData.listing_type)}
                      </Typography>
                      <Typography variant="body1" className="colorGrey">
                        {configJSON.dockTypeLabel}
                      </Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box className="flexCol">
                      <Typography variant="body1" className="fontWeight700">
                        {this.showStateName(listedDockDetailsData.administrative_area_level_1, listedDockDetailsData.administrative_area_level_2)}
                      </Typography>
                      <Typography variant="body1" className="colorGrey">
                        {configJSON.boatDockingAreaLabel}
                      </Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box className="flexCol">
                      <Typography variant="body1" className="fontWeight700">
                        {listedDockDetailsData.water_depth || 0}ft
                      </Typography>
                      <Typography variant="body1" className="colorGrey">
                        {configJSON.waterDepthLabel}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  {this.renderPaymentDetailCard()}
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography
                    variant="body1"
                    className="fontWeight700 subHeader"
                  >
                    {configJSON.amenitiesLabel}
                  </Typography>
                  <Box className="marginTop10 gridCol2">
                  {listedDockDetailsData.features?.length > 0 ? (
                    listedDockDetailsData.features.map((feature, index) => (
                      <Typography variant="body1" key={index}>
                        {feature.name}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      {configJSON.noFeaturesText}
                    </Typography>
                  )}
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography
                    variant="body1"
                    className="fontWeight700 subHeader"
                  >
                    {configJSON.addonsLabel}
                  </Typography>
                  <Box className="marginTop10 gridCol2 gridColGap">
                  {listedDockDetailsData.dock_add_ons && listedDockDetailsData.dock_add_ons.length > 0 ? (
                    listedDockDetailsData.dock_add_ons.map((addon) => (
                      <Box
                        key={addon.id}
                        className="flexItems justifySpaceBetween"
                      >
                        <Box className="flexItems gapNone">
                          <Typography variant="body1">{addon.name}</Typography>
                        </Box>
                        <Typography variant="body1">${addon.price}</Typography>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      {configJSON.noAddonText}
                    </Typography>
                  )}
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box>
                <Typography variant="h6" className="fontWeight700">
                  {configJSON.thingsToKnowHeader}
                </Typography>
                <Grid container className="paddingTop5" spacing={4}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography
                      variant="body1"
                      className="fontWeight700 subHeader"
                    >
                      {configJSON.dockRulesLabel}
                    </Typography>
                    <List>{this.renderDockRules()}</List>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>                    
                    <List>{this.renderDockInstructions()}</List>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography
                      variant="body1"
                      className="fontWeight700 subHeader"
                    >
                      {configJSON.cancellationPolicyText}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="marginTop10"
                    >
                      {`Free cancellation before ${listedDockDetailsData.cancellation_policy?.replace('_', ' ')}.`}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  variant="body1"
                  className="marginTop10 fontWeight700 textDecorationUnderline"
                  onClick={this.handleMoreRules}
                  data-test-id='show-rules'
                >
                  {this.showMoreLessRules()}
                </Typography>
              </Box>
              <Divider style={{marginBottom: '50px'}}/>
            </MainWrapper>
          </Container>
        </ThemeProvider>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Wrapper)({
  "& .backBtn": {
    fontSize: "18px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#64748B",
    textTransform: "none",
  },
  "& .successBox": {
    margin: "40px 0",
    display: "flex",
    gap: 25,
    alignItems: "center",
    justifyContent: "center"
  },
  [theme.breakpoints.down(800)]: {
    "& .successBox": {
      flexDirection: "column",
    }
  }
});
// Customizable Area End